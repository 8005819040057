var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (CourtList, Math, OrgList, styles) {
      pug_mixins["itemWrap"] = pug_interp = function(item, orgType){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Ctd\u003E\u003Cdiv" + (pug.attr("class", pug.classes([styles.tableCell], [true]), false, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["login-org-item",styles.tableInCell], [false,true]), false, true)+pug.attr("data-org-type", orgType, true, true)+pug.attr("data-org-id", item.id, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E";
};
pug_mixins["listWrap"] = pug_interp = function(list, orgType){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([styles.container], [true]), false, true)) + "\u003E\u003Ctable" + (pug.attr("class", pug.classes([styles.table], [true]), false, true)+" cellspacing=\"0\" cellpadding=\"0\" border=\"0\"") + "\u003E\u003Ccolgroup\u003E\u003Ccol width=\"100\"\u003E\u003Ccol width=\"100\"\u003E\u003C\u002Fcolgroup\u003E\u003Ctbody\u003E";
let i = 0
const halfLen = Math.floor(list.length / 2)
while (i < halfLen) {
pug_html = pug_html + "\u003Ctr\u003E";
pug_mixins["itemWrap"](list[i * 2], orgType);
pug_mixins["itemWrap"](list[i * 2 + 1], orgType);
pug_html = pug_html + "\u003C\u002Ftr\u003E";
i++
}
if (list.length % 2 === 1) {
pug_html = pug_html + "\u003Ctr\u003E";
pug_mixins["itemWrap"](list[list.length - 1], orgType);
pug_html = pug_html + "\u003C\u002Ftr\u003E";
}
pug_html = pug_html + "\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E\u003C\u002Fdiv\u003E";
};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["layui-tab","layui-tab-brief",styles.mainTab], [false,false,true]), false, true)) + "\u003E";
if (OrgList.length === 0) {
pug_html = pug_html + "\u003Cul" + (pug.attr("class", pug.classes(["layui-tab-title",styles.mainTabTitle], [false,true]), false, true)) + "\u003E\u003Cli class=\"layui-this\"\u003E法院\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["layui-tab-content",styles.mainTabContent], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"layui-tab-item layui-show\"\u003E";
pug_mixins["listWrap"](CourtList, 'court');
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (CourtList.length === 0) {
pug_html = pug_html + "\u003Cul" + (pug.attr("class", pug.classes(["layui-tab-title",styles.mainTabTitle], [false,true]), false, true)) + "\u003E\u003Cli class=\"layui-this\"\u003E机构\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["layui-tab-content",styles.mainTabContent], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"layui-tab-item layui-show\"\u003E";
pug_mixins["listWrap"](OrgList, 'org');
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cul" + (pug.attr("class", pug.classes(["layui-tab-title",styles.mainTabTitle], [false,true]), false, true)) + "\u003E\u003Cli class=\"layui-this\"\u003E法院\u003C\u002Fli\u003E\u003Cli\u003E机构\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["layui-tab-content",styles.mainTabContent], [false,true]), false, true)) + "\u003E\u003Cdiv class=\"layui-tab-item layui-show\"\u003E";
pug_mixins["listWrap"](CourtList, 'court');
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"layui-tab-item\"\u003E";
pug_mixins["listWrap"](OrgList, 'org');
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "CourtList" in locals_for_with ?
        locals_for_with.CourtList :
        typeof CourtList !== 'undefined' ? CourtList : undefined, "Math" in locals_for_with ?
        locals_for_with.Math :
        typeof Math !== 'undefined' ? Math : undefined, "OrgList" in locals_for_with ?
        locals_for_with.OrgList :
        typeof OrgList !== 'undefined' ? OrgList : undefined, "styles" in locals_for_with ?
        locals_for_with.styles :
        typeof styles !== 'undefined' ? styles : undefined));
    ;;return pug_html;};
module.exports = template;