import { eMsg, simpleLoading } from '@/utils/interaction'
import {
  queryAllOrgOrCourt,
  loginWithCourtRole,
  loginWithOrgRole
} from '@/services/login'
import styles from './index.module.scss'
import RolesPug from './index.pug'

async function openLoginDialog() {
  const { courts: CourtList, orgs: OrgList } = await queryAllOrgOrCourt()
  const totalLength = CourtList.length + OrgList.length
  if (totalLength > 0) {
    if (totalLength === 1) {
      const isCourt = CourtList.length === 1
      const orgType = isCourt ? 'court' : 'org'
      const orgId = isCourt ? CourtList[0].id : OrgList[0].id
      // 自动登录
      await switchOrganization(orgType, orgId)
    } else {
      // 存在多个组织
      const maxRow = Math.ceil(Math.max(CourtList.length, OrgList.length) / 2)
      layer.open({
        type: 1,
        title: '选择用户角色',
        area: ['600px', getLayerHeight(maxRow)],
        shadeClose: true,
        content: RolesPug({ styles, CourtList, OrgList }),
        success(boxEle: HTMLDivElement) {
          $(boxEle).click(function (ev) {
            const target = $(ev.target).closest('.login-org-item')
            if (target.length > 0) {
              const orgType = target.data('orgType')
              const orgId = target.data('orgId')
              switchOrganization(orgType, orgId)
            }
          })
        }
      })
    }
  } else {
    // 没有组织机构
    throw new Error('暂无可用的机构或法院')
  }
}

async function switchOrganization(
  orgType: 'court' | 'org',
  orgId: string | number
) {
  try {
    if (orgType === 'court') {
      await loginWithCourtRole(orgId)
    } else {
      await loginWithOrgRole(orgId)
    }
    window.location.assign('/home.html')
  } catch (err: any) {
    eMsg(err.message)
    throw err
  }
}

export async function userFinalLogin() {
  // 获取当前可用的机构与法院角色
  simpleLoading.show()
  try {
    await openLoginDialog()
    simpleLoading.hide()
  } catch (err: any) {
    simpleLoading.hide()
    eMsg(err.message)
  }
}

function getLayerHeight(num: number) {
  const wHeight = $(window).height()
  return Math.min(wHeight, Math.max(num * 44 + 120, 400)) + 'px'
}
