var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (list) {
      pug_html = pug_html + "\u003Cselect name=\"courtId\" lay-verify=\"required\"\u003E\u003Coption value=\"\"\u003E请选择登录法院\u003C\u002Foption\u003E";
// iterate list
;(function(){
  var $$obj = list;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var item = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.value, true, true)+pug.attr("selected", item.selected, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.text) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var item = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", item.value, true, true)+pug.attr("selected", item.selected, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.text) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003Cimg" + (" class=\"input-icon\""+pug.attr("src", require('./img/lg_court.png'), true, true)) + "\u003E";
    }.call(this, "list" in locals_for_with ?
        locals_for_with.list :
        typeof list !== 'undefined' ? list : undefined));
    ;;return pug_html;};
module.exports = template;