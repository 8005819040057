import * as Cookies from 'js-cookie'

export function getToken() {
  return Cookies.get('token')
}

export function setToken(val: string) {
  localStorage.setItem('token', val)
  return Cookies.set('token', val)
}

export function removeToken() {
  localStorage.removeItem('token')
  Cookies.remove('token')
}

export function goToLoginPage() {
  let toLogin: (() => void) | undefined
  // IE8不支持parent
  for (let win: Window = window; ; win = win.parent || win.top) {
    toLogin = (win as any).navigateToLogin
    if (toLogin || win === win.top) {
      break
    }
  }
  ;(toLogin || navigateToLogin)()
}

export function navigateToLogin() {
  const loginMode = localStorage.getItem('loginMode')
  if (loginMode === 'court') {
    window.location.assign('/court/login.html')
  } else if (loginMode === 'organization') {
    window.location.assign('/org/login.html')
  }else if (loginMode === 'h5score') {
    window.location.assign('/org/login.html')
  } else {
    window.location.assign('/')
  }
}
